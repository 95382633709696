<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="info" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="fa fa-user-plus" />&nbsp;
                    {{translate('make_user')}}
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-info">
                    <q-item class="full-width pl-0 pr-0">
                        <q-item-section class="align-content-center align-items-center col-1 text-muted">
                            <i class="fas fa-user fa-2x"></i>
                        </q-item-section>
                        <q-item-section class="col-11">
                            <small>{{ translate('worker') }}</small>
                            <strong :class="!worker_name ? 'text-danger' : 'text-primary'">{{ worker_name }}</strong>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info">
                    {{ translate('making_user') }}<br />{{ translate('making_user_additional_info') }}
                </b-list-group-item>
                <q-separator />
                <b-list-group-item class="list-group-item-accent-info">
                    <q-item class="p-0 full-width">
                        <q-item-section side>
                            <b-button @click.prevent="makeUser()" type="submit" variant="success">
                                <q-icon size="1rem" name="check" /> {{translate('create')}}
                            </b-button>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <b-button @click.prevent="closePanel" type="button" variant="secondary">
                                <q-icon size="1rem" name="cancel" /> {{translate('cancel')}}
                            </b-button>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import { eventBus } from '../../../../main'

export default {
    name: 'MakeUser',
    props: ['worker_id', 'worker_email', 'worker_name'],
    data() {
        return {

        }
    },
    methods: {
        makeUser: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var worker_id = this.worker_id

            axios.post(baseUrl + 'users/worker?email=' + this.worker_email, null, {
                    headers: headers
                })
                .then(response => {
                    var message = 'make_user_' + response.data.msg
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        eventBus.$emit('update_worker_is_user', worker_id)
                        this.$emit('closePanel', {
                            status: true
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })

        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>
