<template>
<div class="fit edit-panel sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div :class="'pull-right'">
                    <q-btn class="mr-2" :label="!$q.screen.lt.sm ? translate('delete') : ''" no-caps dense color="danger" @click.prevent="showDeleteEmployeePanel(worker_id)" icon="delete" flat />
                    <toggle-active :no_label="$q.screen.lt.sm ? true : null" @closePanel="closePanel()" :is_active="is_active" :patch_toggle_route="patch_toggle_route" :item_id="worker_id" :entity_type="entity_type" />
                </div>
                <div class="m-1"><q-icon name="edit" class="mr-1" />{{ translate('edit_worker') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent class="full-height">
                    <b-list-group-item class="p-0 pt-2 pb-2">
                        <div class="row m-0">
                            <div class="col p-0 pl-4">
                                <q-item-section side class="full-height align-self-center">
                                    <q-btn v-if="is_user === false" icon="fa fa-user-plus" no-caps color="primary" :label="translate('make_it_user')" @click="showMakeUserPanel(worker_id)" />
                                    <q-item class="p-0" v-if="is_user === true">
                                        <q-item-section side><q-icon name="fas fa-user-plus" color="primary" /></q-item-section>
                                        <q-item-section>{{ translate('worker_is_user') }}</q-item-section>
                                    </q-item>
                                </q-item-section>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input dense square color="primary" :label="translate('first_name')" type="text" id="first_name" v-model="worker.first_name" :placeholder="translate('enter_firstname')" />
                            </div>
                            <div class="col-md p-0">
                                <q-input dense square color="primary" :label="translate('last_name')" type="text" id="last_name" v-model="worker.last_name" :placeholder="translate('enter_lastname')" />
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input dense square color="primary" :label="translate('email')" type="email" id="email" v-model="worker.email" :placeholder="translate('enter_email')" />
                            </div>
                            <div class="col-md p-0">
                                <q-input dense square color="primary" :label="translate('phone')" type="tel" id="phone_number" v-model="worker.phone_number" :placeholder="translate('enter_phone')" />
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-select :label="translate('gender')" class="full-width" square color="primary" v-model="worker.gender" :options="gender" no-error-icon />
                            </div>
                            <div class="col-md p-0">
                                <q-input :label="translate('birthday')" class="full-width" id="birthday" color="primary" square v-model="worker.birth_date">
                                    <template v-slot:append>
                                        <q-icon name="event" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                <q-date first-day-of-week="1" v-model="worker.birth_date" mask="YYYY-MM-DD" />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="m-3 font-weight-bold">{{ translate('profile_picture') }}</div>
                <b-list-group-item>
                    <q-item class="p-0 full-width profile-picture-wrapper" style="min-height: 0">
                        <q-item-section side>
                            <div class="worker-picture-container">
                                <img v-if="worker.image_link" :src="worker.image_link">
                                <i v-else class="fas fa-user fa-1x text-muted"></i>
                            </div>
                        </q-item-section>
                        <q-item-section>
                            <q-item tag="label" clickable class="p-2 bg-positive text-white mt-2" v-ripple>
                                <q-item-section class="p-0" side>
                                    <q-icon class="mr-1" size="1.5rem" color="light" name="fas fa-photo"></q-icon>
                                </q-item-section>
                                <q-item-section class="p-0 text-center">
                                    <small>{{ translate('add_image') }}</small>
                                </q-item-section>
                                <q-item-section class="p-0 text-right" side>
                                    <q-icon size="1rem" color="light" name="fas fa-upload"></q-icon>
                                    <b-form-file class="hidden" type="file" name="selectedFile" @change="onFileSelected" v-model="selectedFile"></b-form-file>
                                </q-item-section>
                            </q-item>
                        </q-item-section>
                        <q-item-section>
                            <q-item tag="label" clickable class="p-2 bg-negative text-white mt-md-2" v-ripple @click.stop="showDeleteProfileImagePanel()">
                                <q-item-section class="p-0" side>
                                    <q-icon class="mr-1" size="1.5rem" color="light" name="fas fa-photo"></q-icon>
                                </q-item-section>
                                <q-item-section class="p-0 text-center">
                                    <small>{{ translate('delete_image') }}</small>
                                </q-item-section>
                                <q-item-section class="p-0 text-right" side>
                                    <q-icon size="1rem" color="light" name="delete"></q-icon>
                                </q-item-section>
                            </q-item>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="editWorker">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../../main'
import DeleteEmployee from './DeleteEmployee'
import ToggleActive from '../../../template-parts/ToggleActive'
import MakeUser from './MakeUser'
import DeleteProfileImage from '../../../template-parts/DeleteProfileImage'

export default {
    name: 'EditEmployee',
    props: ['worker_id', 'is_active', 'is_user' , 'baseUrl', 'entity_type', 'patch_toggle_route'],
    components: {
        'toggle-active': ToggleActive,
    },
    computed: {
        delete_profile_image_route: function () {
            return baseUrl + 'profile_images/worker/' + this.worker_id
        },
        image_upload_route: function () {
            return baseUrl + 'profile_images/worker'
        },
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            selectedFile: null,
            worker: {
                first_name: null,
                last_name: null,
                email: null,
                phone_number: null,
                birth_date: null,
                gender: null
            },
            selected: null,
            gender: [{
                    value: 'f',
                    label: 'Female'
                },
                {
                    value: 'm',
                    label: 'Male'
                },
            ],
        }
    },
    created: function () {
        this.getWorker()
        eventBus.$on('updateCurrentUserProfileImage', (item) => {
            this.updateRemoveWorkerImageLink(item)
        })
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        updateRemoveWorkerImageLink: function (item) {
            this.worker.image_link = item && item.image_link ? item.image_link : null
        },
        onFileSelected: function (event) {
            this.selectedFile = event.target.files[0]
            this.selectedFile.image_name = this.selectedFile.name.split(' ').join('_')
            this.uploadImage()
        },
        uploadImage: function () {
            const formData = new FormData()
            var file_name = this.selectedFile.name.split(' ').join('_')
            formData.append('file', this.selectedFile, file_name)
            formData.set('worker_id', this.worker_id)
            

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'multipart/form-data'
            }
            axios.post(this.image_upload_route, formData, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'images_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        // Update image.
                        this.selectedFile = null
                        this.getWorker(true)
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    // console.log(error)
                })
        },
        showDeleteProfileImagePanel: function () {
            const panelInstance = this.$showPanel({
                component: DeleteProfileImage,
                props: {
                    delete_profile_image_route: this.delete_profile_image_route,
                    user_id: this.worker_id
                }
            })
        },
        showMakeUserPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: MakeUser,
                props: {
                    worker_id: id,
                    worker_email: this.worker.email,
                    worker_name: this.worker.first_name + ' ' + this.worker.last_name
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        showDeleteEmployeePanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteEmployee,
                props: {
                    worker_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        getWorker: function (emmit) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.get(baseUrl + 'workers/' + this.worker_id, {
                    headers: headers
                })
                .then(response => {
                    this.worker = response.data.item
                    this.worker['image_link'] = response.data.item.image_link ? baseUrl + response.data.item.image_link : null
                    if (emmit) {
                        var worker_image_link = this.worker.image_link
                        eventBus.$emit('updateCurrentUserProfileImage', { id: this.worker_id, image_link: worker_image_link })
                    }
                    if (response.data.item.birth_date) {
                        this.worker.birth_date = response.data.item.birth_date.slice(0, 10)
                        var worker_gender = {}
                        this.gender.map((element) => {
                            if (response.data.item.gender === element.value) {
                                worker_gender = {
                                    'label': element.label,
                                    'value': element.value
                                }
                            }
                            return
                        })
                        this.worker.gender = worker_gender
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        editWorker: function () {
            var worker_id = this.worker_id
            var worker_name = this.worker.first_name + ' ' + this.worker.last_name
            var data = {
                'first_name': this.worker.first_name,
                'last_name': this.worker.last_name,
                'email': this.worker.email,
                'phone_number': this.worker.phone_number,
                'birth_date': this.worker.birth_date,
                'gender': this.worker.gender.value,
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'workers/' + this.worker_id, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_worker_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: true,
                            worker: { id: worker_id, name: worker_name }
                        })                        
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-picture-wrapper {
    .worker-picture-container {
        border: 2px solid #fff;
        background-clip: border-box;
        box-shadow: 0 0 6px rgba(0, 0, 0, .36);

        i.fa-user {
            width: 150px;
            height: 80px;
            line-height: 80px;
            vertical-align: middle;
            font-size: 2rem;
            text-align: center;
        }
        img {
            width: auto;
            max-width: 150px;
            height: auto;
            max-height: 80px;
        }
    }
}

@media screen and (max-width: 767px) {
.profile-picture-wrapper {
    flex-flow: wrap;
    flex-direction: column;

    > .q-item__section {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .worker-picture-container {
        width: 100%;

        i.fa-user {
            width: 100%;
            height: 100px;
            line-height: 100px;
            font-size: 3rem;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
}
</style>
