<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" />{{ translate('add_worker') }}
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent class="full-height">
                    <b-list-group-item class="list-group-item-accent-info p-0 pt-2 pb-2">
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input dense square color="primary" type="text" id="first_name" v-model="worker.first_name" :placeholder="translate('first_name')" />
                            </div>
                            <div class="col-md p-0">
                                <q-input dense square color="primary" type="text" id="last_name" v-model="worker.last_name" :placeholder="translate('last_name')" />
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input dense square color="primary" type="email" id="email" v-model="worker.email" :placeholder="translate('email')" />
                            </div>
                            <div class="col-md p-0">
                                <q-input dense square color="primary" type="tel" id="phone_number" v-model="worker.phone_number" :placeholder="translate('phone')" />
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-select :label="!worker.gender || worker.gender.length === 0 ? translate('gender') : ''" class="full-width" square color="primary" v-model="worker.gender" :options="gender_options" map-options no-error-icon />
                            </div>
                            <div class="col-md p-0">
                                <q-input :label="!worker.birth_date || worker.birth_date.length === 0 || worker.birth_date === 'YYYY-mm-dd' ? translate('birthday') : ''" @focus="$refs.qDateProxy.show()" @click="$refs.qDateProxy.show()" @input="$refs.qDateProxy.show()" light square color="primary" class="full-width" id="birth_date" v-model="worker.birth_date">
                                    <template v-slot:append>
                                        <q-icon name="event" class="cursor-pointer pointer-events-none">
                                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                                <q-date first-day-of-week="1" v-model="worker.birth_date" mask="YYYY-MM-DD" @input="(val) => onUpdateDate(worker.birth_date)" />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <div v-if="button" class="p-3">
                            <b-button @click.prevent="makeUser()" type="button" variant="primary">
                                <i class="fas fa-user-plus"></i> {{ translate('make_it_user') }}
                            </b-button>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerWorker">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'AddEmployee',
    computed: {
        gender_options: function () {
            return [{
                    value: 'f',
                    label: this.translate('gender_female')
                },
                {
                    value: 'm',
                    label: this.translate('gender_male')
                },
            ]
        }
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            status: null,
            worker: {
                first_name: null,
                last_name: null,
                email: null,
                phone_number: null,
                birth_date: null,
                gender: null
            },
            submit: false,
            button: false
        }
    },
    created: function () {
        this.setDefaultWorkerBirthday()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        onUpdateDate: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qDateProxy.hide()
            })
        },
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        setDefaultWorkerBirthday: function () {
            this.worker.birth_date = 'YYYY-mm-dd'
        },
        makeUser: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.post(baseUrl + 'users/worker?email=' + this.worker.email, null, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'make_user_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })

        },
        registerWorker: function () {
            var data = {
                'first_name': this.worker.first_name,
                'last_name': this.worker.last_name,
                'email': this.worker.email,
                'phone_number': this.worker.phone_number,
                'birth_date': this.worker.birth_date
            }
            if (this.worker.gender && this.worker.gender.value) {
                data['gender'] = this.worker.gender.value
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.post(baseUrl + 'workers', data, {
                    headers: headers
                })
                .then(response => {
                    this.status = response.data.status
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_worker_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        if (!this.submit) {
                            this.submit = true
                            this.button = true
                            localStorage.setItem('makeUser', this.submit)
                        }
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {
                status: this.status
            })
        }
    }
}
</script>
