<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <h3>{{translate('worker_names')}}</h3>
        </div>
        <search-wrapper-card :active="worker_active" :activeStatus="activeStatus" :inactive="worker_inactive" :inactiveStatus="inactiveStatus" :typeDisable="false" form_type="workers">
            <q-input slot="search_input" bottom-slots @input="isTyping = true" v-model="searchQuery" id="filternameworker" class="search-input" type="text" :placeholder="translate('find_worker')" :dense="true">
                <template v-slot:prepend>
                    <q-icon name="search" />
                </template>
            </q-input>
            <template slot="add_button">
                <q-btn v-if="!$q.screen.lt.sm" @click.stop.prevent="showAddEmployeePanel" type="button" no-caps color="primary">
                    <i class="fas fa-plus mr-1"></i> {{translate('add_worker')}}
                </q-btn>
                <q-btn v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" dense round no-caps color="primary" type="button" @click.stop.prevent="showAddEmployeePanel" />
            </template>
        </search-wrapper-card>
    </b-card>
    <div class="list-group wrapper_workers-list">
        <q-item v-for="(worker, i) in workers" :key="'worker-item-' + i + '-' + (i.id,worker.id)" :id="worker.id" class="list-item full-width" 
            :class="worker.is_active ? 'worker-active' : 'worker-inactive'">
            <q-item-section avatar class="p-0">
                <q-avatar class="ml-2 shadow-2 cursor-pointer" @click.prevent="showEditEmployeePanel(worker.id, worker.is_active, worker.is_user)">
                    <img v-if="worker.image_link" class="black-and-white" :src="worker.image_link">
                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                </q-avatar>
            </q-item-section>
            <q-item-section class="full-height align-self-center pt-2 pb-2">
                <strong class="text-muted">{{ worker.name }} &lt;{{ translate(worker.email) }}&gt;</strong>
            </q-item-section>
            <!-- [+] Desktop sections. -->
            <template v-if="!$q.screen.lt.sm">
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn @click="showEditEmployeePanel(worker.id, worker.is_active, worker.is_user)" v-if="worker.is_user === false" round icon="fas fa-user" color="primary">
                        <q-tooltip :offset="[10, 10]">{{ translate('worker_is_not_user') }}</q-tooltip>
                    </q-btn>
                    <q-btn @click="showEditEmployeePanel(worker.id, worker.is_active, worker.is_user)" v-if="worker.is_user === true" round icon="fa fa-user-plus" color="primary">
                        <q-tooltip :offset="[10, 10]">{{ translate('worker_is_user') }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <q-btn round icon="delete" color="danger" @click="showDeleteEmployeePanel(worker.id)">
                        <q-tooltip :offset="[10, 10]">{{ translate('delete_' + entity_type) }}</q-tooltip>
                    </q-btn>
                </q-item-section>
                <q-item-section side class="full-height align-self-center pt-2 pb-2">
                    <toggle-active :is_active="worker.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="worker.id" :entity_type="entity_type" />
                </q-item-section>
            </template>
            <!-- [-] Desktop sections. -->
        </q-item>
    </div>
    <infinite-loading :identifier="'workers-' + infWorkers" slot="append" @infinite="getWorkers" />
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import SearchWrapperCard from '../../template-parts/SearchWrapperCard'
import EditEmployee from './forms/EditEmployee'
import DeleteEmployee from './forms/DeleteEmployee'
import AddEmployee from './forms/AddEmployee'
import ToggleActive from '../../template-parts/ToggleActive'

export default {
    name: 'Workers',
    components: {
        'search-wrapper-card': SearchWrapperCard,
        'toggle-active': ToggleActive,
    },
    computed: {
        entity_type: function () {
            return 'worker'
        },
        patch_toggle_route: function () {
            return baseUrl + 'workers'
        },
    },
    data: function () {
        return {
            workers: [],
            searchQuery: "",
            order_by: '',
            worker_active: 'active',
            worker_inactive: '',
            isTyping: false,
            state: {},
            page: 1,
            infWorkers: +new Date(),
        }
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.isTyping = false
        }, 200),
        isTyping: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    mounted: function () {
        eventBus.$on('toggle_active_worker', (item) => {
            this.toggleActiveWorker(item)
        })
        eventBus.$on('delete_worker', (id) => {
            this.deleteWorker(id)
        })
        eventBus.$on('update_worker_is_user', (id) => {
            this.updateWorkerIsUser(id)
        })
        eventBus.$on('updateCurrentUserProfileImage', (item) => {
            this.updateWorkerImageLink(item)
        })
    },
    methods: {
        updateWorkerImageLink: function (item) {
            // Find index of specific object using findIndex method,
            var objIndex = this.workers.findIndex((obj => obj.id === item.id))
            // and update the image_link from the workers array.
            this.workers[objIndex]['image_link'] = item.image_link ? item.image_link : null
        },
        updateWorkerName: function (item) {
            // Find index of specific object using findIndex method,
            var objIndex = this.workers.findIndex((obj => obj.id === item.worker.id))
            // and update the element from the workers array.
            this.workers[objIndex]['name'] = item.worker.name ? item.worker.name : ''
        },
        toggleActiveWorker: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.workers.findIndex((obj => obj.id === item.id))
                if (this.workers[objIndex]) {
                    // And update is_active for the given item in list.
                    this.workers[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)

                    if (item.is_active === 0 && this.worker_active === 'active' && this.worker_inactive === '') {
                        this.deleteWorker(item.id)
                    }
                    if (item.is_active === 1 && this.worker_active === '' && this.worker_inactive === 'inactive') {
                        this.deleteWorker(item.id)
                    }
                }
            }
        },
        updateWorkerIsUser: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.workers.findIndex((obj => obj.id === id))
            // and update the is_user for the given worker.
            this.workers[objIndex]['is_user'] = true
        },
        deleteWorker: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.workers.findIndex((obj => obj.id === id))
            // and remove the element from the workers array.
            this.workers.splice(objIndex, 1)
        },
        resetMainList: function () {
            this.page = 1
            this.workers = []
            this.infWorkers++
        },
        activeStatus: _.debounce(function () {
            if (this.worker_active === 'active') {
                this.worker_active = ''
            } else {
                this.worker_active = 'active'
            }
            this.resetMainList()
        }, 200),
        inactiveStatus: _.debounce(function () {
            if (this.worker_inactive === 'inactive') {
                this.worker_inactive = ''
            } else {
                this.worker_inactive = 'inactive'
            }
            this.resetMainList()
        }, 200),
        getWorkers: function ($state) {
            var workerUrl = baseUrl +
                    'workers?page=' + this.page +
                    '&items_per_page=' + this.$items_per_page
                    
            if (this.searchQuery) {
                workerUrl += '&search=' + this.searchQuery
            }
            if (this.worker_active === 'active' && this.worker_inactive === '') {
                workerUrl += '&worker_status=' + this.worker_active
            }
            if (this.worker_active === '' && this.worker_inactive === 'inactive') {
                workerUrl += '&worker_status=' + this.worker_inactive
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let workers = this.workers
            axios.get(workerUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        workers.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            return array
                        }))
                    } 
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.workers = workers
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeleteEmployeePanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteEmployee,
                props: {
                    worker_id: id
                }
            })
        },
        showEditEmployeePanel: function (id, is_active, is_user) {
            const panelInstance = this.$showPanel({
                component: EditEmployee,
                props: {
                    worker_id: id,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route,
                    is_active: is_active,
                    is_user: is_user
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateWorkerName(result)
                    }
                })
        },
        showAddEmployeePanel: function () {
            const panelInstance = this.$showPanel({
                component: AddEmployee,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
[v-cloak] {
    display: none;
}

.search-input {
    margin-left: -15px !important;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    img.black-and-white {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
    &:hover {
        border-color: rgba(0, 0, 0, .26);
        text-decoration: none;
        
        i {
            color: var(--q-color-negative)!important;
        }
        img.black-and-white {
            -webkit-filter: grayscale(0); /* Safari 6.0 - 9.0 */
            filter: grayscale(0);
        }
    }
}

.worker-active {
    border-left: 2px solid var(--q-color-primary)!important;
}
.worker-inactive {
    border-left: 2px solid var(--q-color-dark)!important;
}
</style>
